<template>
  <div>
    <div class="col-md-6" style="padding-left: 0;">
      <PanelBox>
        <template #title>
          <span
            v-if="property.mandate_info && property.mandate_info.expired && property.mandate_info.description"
            class="tw-items-center tw-font-semibold tw-px-2 tw-py-1 tw-rounded tw-text-white tw-bg-danger tw-block tw-w-full tw-mb-1"
          >
            Mandaat is verlopen: {{ property.mandate_info.description }}
          </span>
          <PropertyStatus
            :is-archived="property.is_archived"
            :status-display="property.status_display_nl"
          />
        </template>
        <template #toolbar>
          <a
            v-show="showSuspensiveConditionButton"
            title="Opschortende voorwaarde"
            class="toolbar-button tw-bg-success !tw-text-white"
            @click="showSuspensiveConditionModal"
          >
            <i class="fa fa-warning" /> Opschortende VW
          </a>
          <a
            v-if="property.status === 1 && !property.is_archived"
            title="Dossier aanvullen"
            class="toolbar-button tw-bg-success !tw-text-white"
            :disabled="loadingWizardDossierDetails"
            @click="showWizardDossierDetails"
          >
            <i :class="['fa', loadingWizardDossierDetails ? 'fa-spinner-third fa-spin' : 'fa-list']" /> Dossierwizard
          </a>
          <a
            v-if="property.status === 1 && !property.is_archived"
            title="Opnemen in portefeuille"
            class="toolbar-button tw-bg-success !tw-text-white"
            @click="showWizardDossieropbouw"
          >
            <i class="fas fa-gavel" /> Opnemen in portefeuille
          </a>
          <a
            v-if="property.status === 16 && !property.is_archived"
            title="Publiceren"
            class="toolbar-button tw-bg-success !tw-text-white"
            @click="showWizardPubliceren"
          >
            <i class="fas fa-badge-check" /> Publiceren
          </a>
          <!-- Only enabled in case of "for sale" properties -->
          <template v-if="property.stage">
            <a
              v-if="property.stage.key === 'bid_registration'"
              title="Bodregistratie"
              class="toolbar-button tw-bg-success !tw-text-white"
              @click="showWizardBidRegistration"
            >
              <i class="fas fa-gavel" /> Bodregistratie
            </a>
            <a
              v-if="property.stage.key === 'sales_agreement'"
              title="Compromis"
              class="toolbar-button tw-bg-success !tw-text-white"
              @click="showWizardSalesAgreement"
            >
              <i class="fas fa-handshake-alt" /> Compromis is ondertekend
            </a>
            <a
              v-if="property.stage.key === 'rental_agreement'"
              title="Afhandeling huurovereenkomst"
              class="toolbar-button tw-bg-success !tw-text-white"
              @click="showWizardRentalAgreement"
            >
              <i class="fas fa-handshake-alt" /> Afhandeling huurovereenkomst
            </a>
            <a
              v-if="property.stage.key === 'deed'"
              title="Akte"
              class="toolbar-button tw-bg-success !tw-text-white"
              @click="showWizardDeed"
            >
              <i class="fas fa-file" /> Akte
            </a>
          </template>
          <router-link
            :to="{ name: 'EntityMaps'}"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fa fa-map" /> Kaarten
          </router-link>
          <router-link
            :to="{ name: 'PropertyGeneralEdit', params: { formName: 'address' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </template>
        <div>
          <div class="tw-flex">
            <div v-show="mandateEndsSoon" class="tw-mb-4">
              <span class="tw-px-2 tw-py-1 tw-mr-1 tw-inline-block tw-rounded tw-bg-[#ed5565] tw-text-white tw-font-semibold">
                In opzegperiode
              </span>
            </div>
            <Stickers :stickers="stickers" />
          </div>
          <div class="tw-grid tw-grid-cols-2 tw-gap-x-2">
            <dl class="tw-grid md:tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
              <template v-if="project">
                <dt>Project</dt>
                <dd>
                  <router-link :to="{ name:'ProjectDetails', params: { id: property.project } }">
                    {{ project.name }}
                  </router-link>
                  <span v-if="property.unit_no">Unit {{ property.unit_no }}</span>
                </dd>
                <template v-if="property.fase">
                  <dt>Fase</dt>
                  <dd>{{ property.fase_display }}</dd>
                </template>

              </template>

              <dt>Concept</dt>
              <dd v-if="concept">{{ concept.name }}</dd>

              <dt>Type <i class="far fa-globe-europe" /></dt>
              <dd>{{ property.type.name_nl || 'Niet ingegeven' }}</dd>

              <dt>Prijs</dt>
              <dd>{{ currency(financialPrice && financialPrice.price) }}</dd>

              <dt>Prijs zichtbaarheid</dt>
              <dd v-if="financialPrice">{{ financialPrice.published ? 'Publiceer prijs' : 'Publiceer prijs niet' }}</dd>

              <dt>Adres zichtbaarheid</dt>
              <dd>{{ ADDRESS_PUBLISH_OPTIONS[property.publish_address] }}</dd>

              <dt>Website</dt>
              <dd>
                <span :class="['label', property.published ? 'label-primary' : 'label-danger']">
                  {{ property.published ? 'Gepubliceerd' : 'Offline' }}
                </span>
              </dd>

              <dt>Beschikbaar vanaf <i class="far fa-globe-europe" /></dt>
              <dd>
                <span v-if="property.is_immediately_available">
                  Onmiddellijk beschikbaar
                </span>
                <span v-else-if="property.available_from">
                  {{ property.available_from | date-day }}
                </span>
                <span v-else>
                  Onbekend
                </span>
              </dd>
            </dl>
            <router-link :to="{ name: 'PropertyMedia' }" class="tw-flex tw-justify-center tw-pb-6">
              <img
                v-if="mainPicture"
                :src="mainPicture.url_thumbnail"
                class="tw-rounded-sm tw-object-contain tw-max-h-44"
              />
              <img v-else src="@/assets/img/property_dummy.gif" class="tw-rounded-sm tw-object-contain tw-max-h-44">
            </router-link>
          </div>
          <div class="tw-text-xs">
            <dt>Bezoekregeling</dt>
            <dd class="tw-mb-4 !tw-break-normal">{{ property.visit_arrangement || 'Niet ingegeven' }}</dd>
            <dt>Sleutelregeling</dt>
            <dd class="tw-mb-4 !tw-break-normal">{{ property.key_arrangement || 'Niet ingegeven' }}</dd>
            <!-- Only show this field when it's a rental property
              status 3 = for rent
              status 11 = rented -->
            <template v-if="property && (property.status == 3 || property.status == 11)">
              <dt>Commissieloon verhuur</dt>
              <dd class="tw-mb-4 !tw-break-normal">{{ property.rental_commission_type_display || 'Niet ingegeven' }}</dd>
              <dt>Kostprijs plaatsbeschrijving</dt>
              <dd class="tw-mb-4 !tw-break-normal">{{ property.cost_price_inventory_of_fixtures || 'Niet ingegeven' }}</dd>
            </template>
            <dt>Notities</dt>
            <dd class="tw-mb-4 !tw-break-normal">{{ property.notes || 'Niet ingegeven' }}</dd>
          </div>
          <div v-if="property.city" class="tw-text-xs">
            <a :href="googleMapsAddress" target="_blank">
            {{ property.street | default_if_none('') }} {{ property.number | default_if_none('') }} {{ property.box | default_if_none('') }}
            <br />
            {{ property.city.zip_code }} {{ property.city.name }}
            </a>
          </div>

          <div class="row" style="padding-left: 15px;">
            <p class="col-sm-12 no-padding" style="padding-top: 10px!important; margin-block-end: 0;">
              <small v-if="property.created_on">Aangemaakt op {{ property.created_on|datetime }}
                <span v-if="property.created_by">
                  door {{ property.created_by.first_name }} {{ property.created_by.last_name }}
                </span>
              </small>
            </p>
            <p class="col-sm-12 no-padding" style="margin-block-end: 0;">
              <small v-if="property.updated_on">
                Gewijzigd op {{ property.updated_on|datetime }}
                <span v-if="property.updated_by">door
                  {{ property.updated_by.first_name }} {{ property.updated_by.last_name }}
                </span>
              </small>
            </p>
          </div>
        </div>
      </PanelBox>
      <EntityCollaboratorsPanelBox
        :entity-id="propertyId"
        entity-type="property"
        :property-transaction-type="property.transaction_type"
      />
      <PanelBox title="Kantoren">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'PropertyGeneralEdit', params: { formName: 'offices' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>
        <div
          v-for="office in offices"
          :key="office.id"
          class="tw-flex tw-gap-x-3 tw-mb-2"
        >
          <button
            v-if="office.primary"
            type="button"
            class="btn btn-xs btn-primary tw-w-24"
          >
            Primair
          </button>
          <button
            v-else
            type="button"
            class="btn btn-xs btn-primary btn-outline tw-w-24"
            @click="makeOfficePrimary(office.id)"
          >
            Maak primair
          </button>
          <strong class="tw-w-10 tw-text-center">
            {{ office.reference }}
          </strong>
          <span>
            {{ office.name }}
          </span>
        </div>
      </PanelBox>
      <EntityAuditTrail :key="trailKey" :property-id="propertyId" />

    </div>
    <div class="col-md-6" style="padding-right: 0;">
      <PanelBox title="Kandidaten">
        <span slot="toolbar">
          <button
            type="button"
            class="toolbar-button"
            @click="showCandidateHelpModal"
          >
            <i class="fa fa-question" /> Help
          </button>
        </span>
        <table class="tw-table-auto tw-w-full tw-max-w-md">
          <tbody>
            <tr>
              <th>Kandidaten nog in aanmerking</th>
              <td v-if="statistics">
                {{ statistics.active_leads_count }}
                <router-link :to="{ name: 'PropertyLeads', query: { status: [0, 1, 4] } }" class="btn-link">
                  <i class="fa fa-eye" />
                </router-link>
              </td>
              <td v-else><em>gegevens niet gevonden</em></td>
            </tr>
            <tr>
              <th>Kandidaten nog niet gelinkt aan het pand</th>
              <td v-if="statistics">
                {{ statistics.pending_leads_count }}
                <button @click="showHmodhPropertiesModal" class="btn-link">
                  <i class="fa fa-eye" />
                </button>
              </td>
              <td v-else><em>gegevens niet gevonden</em></td>
            </tr>
            <tr>
              <th>Kandidaten die geen interesse meer hebben</th>
              <td v-if="statistics">
                {{ statistics.inactive_leads_count }}
                <router-link :to="{ name: 'PropertyLeads', query: { status: [2] } }" class="btn-link">
                  <i class="fa fa-eye" />
                </router-link>
              </td>
              <td v-else><em>gegevens niet gevonden</em></td>
            </tr>
            <tr>
              <th>Kandidaten met manuele hmodh</th>
              <td>
                <button type="button" class="fa fa-eye" @click="doHmodhSearch"/>
              </td>
            </tr>
          </tbody>
        </table>
      </PanelBox>

      <PanelBox title="Betrokken partijen">
        <div class="tw-relative tw-grid md:tw-grid-cols-2 tw-gap-4 tw-rounded-dd tw-shadow-sm tw-p-4">
          <div class="tw-absolute tw-flex tw-flex-col tw-gap-3 tw-right-0 tw-top-0 tw--translate-y-2 tw-translate-x-1/2">
            <router-link
              :to="{ name: 'PropertyOwnersEdit' }"
              title="Bewerken"
              class="action-button tw-bg-white tw-text-success tw-border-success hover:tw-bg-success"
            >
              <i class="fas fa-pencil" />
            </router-link>
          </div>
          <div>
            <h4 class="!tw-mt-0 !tw-mb-4">Eigenaars</h4>
            <div class="tw-flex tw-flex-col tw-gap-4">
              <div v-for="owner in owners" :key="owner.id">
                <EntityContact :contact="owner" />
              </div>
            </div>
          </div>

          <div>
            <h4 class="!tw-mt-0 !tw-mb-4">Facturatiecontacten</h4>
            <div class="tw-flex tw-flex-col tw-gap-4">
              <div
                v-for="(invoiceContact, index) in invoiceContactsOwners"
                :key="`invoiceContact_${index}`"
                class="tw-border tw-p-2 tw-rounded"
              >
                <EntityInvoiceContactsDisplay :invoice-contact="invoiceContact" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="showBuyers" class="tw-relative tw-grid md:tw-grid-cols-2 tw-gap-4 tw-rounded-md tw-shadow-sm tw-p-4 tw-mt-4">
          <div class="tw-absolute tw-flex tw-flex-col tw-gap-3 tw-right-0 tw-top-0 tw--translate-y-2 tw-translate-x-1/2">
            <router-link
              :to="{ name: 'PropertyBuyersEdit' }"
              title="Bewerken"
              class="action-button tw-bg-white tw-text-success tw-border-success hover:tw-bg-success"
            >
              <i class="fas fa-pencil" />
            </router-link>
          </div>
          <div>
            <h4 class="!tw-mt-0 !tw-mb-4">Kopers</h4>
            <div class="tw-flex tw-flex-col tw-gap-4">
              <div v-for="buyer in buyers" :key="buyer.id">
                <EntityContact :contact="buyer" />
              </div>
            </div>
          </div>

          <div>
            <h4 class="!tw-mt-0 !tw-mb-4">Facturatiecontacten</h4>
            <div class="tw-flex tw-flex-col tw-gap-4">
              <div
                v-for="(invoiceContact, index) in invoiceContactsBuyers"
                :key="`invoiceContact_${index}`"
                class="tw-border tw-p-2 tw-rounded"
              >
                <EntityInvoiceContactsDisplay :invoice-contact="invoiceContact" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="showRenters" class="tw-relative tw-grid md:tw-grid-cols-2 tw-gap-4 tw-rounded tw-shadow-sm tw-p-4 tw-mt-4">
          <div class="tw-absolute tw-flex tw-flex-col tw-gap-3 tw-right-0 tw-top-0 tw--translate-y-2 tw-translate-x-1/2">
            <router-link
              :to="{ name: 'PropertyRentersEdit' }"
              title="Bewerken"
              class="action-button tw-bg-white tw-text-success tw-border-success hover:tw-bg-success"
            >
              <i class="fas fa-pencil" />
            </router-link>
          </div>
          <div>
            <h4 class="!tw-mt-0 !tw-mb-4">Huurders</h4>
            <div class="tw-flex tw-flex-col tw-gap-4">
              <div v-for="renter in renters" :key="renter.id">
                <EntityContact :contact="renter" />
              </div>
            </div>
          </div>

          <div>
            <h4 class="!tw-mt-0 !tw-mb-4">Facturatiecontacten</h4>
            <div class="tw-flex tw-flex-col tw-gap-4">
              <div
                v-for="(invoiceContact, index) in invoiceContactsRenters"
                :key="`invoiceContact_${index}`"
                class="tw-border tw-p-2 tw-rounded"
              >
                <EntityInvoiceContactsDisplay :invoice-contact="invoiceContact" />
              </div>
            </div>
          </div>
        </div>
      </PanelBox>
      <EntityContacts
        entityType="property"
        :entity-id="propertyId"
      />
    </div>
    <EntityMapModal
      ref="mapModal"
      :geolocation="geolocation"
      :entity="property"
      :parcelString="property.parcels"
      @hide="hideMapModal"
    />
    <CandidateHelpModal ref="candidateHelpModal" />
    <HmodhPropertiesModal ref="hmodhPropertiesModal" :property="property" />
    <WizardPubliceren
      ref="wizardPubliceren"
      :property-id="propertyId"
      :primaryOffice="primaryOffice"
    />
    <WizardDossierDetails
      ref="wizardDossierDetails"
      :property="property"
    />
    <WizardDossieropbouw
      ref="wizardDossieropbouw"
      :property="property"
      :map-visible="mapVisible"
      @showMapModal="showMapModal"
    />
    <WizardDeed
      ref="wizardDeed"
      :property-id="propertyId"
      @dossierCancelled="handleDossierCancelled"
    />
    <WizardSalesAgreement
      ref="wizardSalesAgreement"
      :property="property"
      @submitted="handleSalesAgreementSubmitted"
    />
    <WizardRentalAgreement
      ref="wizardRentalAgreement"
      :property="property"
    />
    <SuspensiveConditionModal
      ref="suspensiveConditionModal"
      :property-id="propertyId"
      @suspensiveConditionFinished="handleSuspensiveConditionFinished"
      @suspensiveConditionEdited="handleSuspensiveConditionEdited"
      @suspensiveConditionCancelled="handleDossierCancelled"
    />
    <StartFinalInvoiceModal ref="startFinalInvoiceModal" />
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId'
import EventBus from '@/components/iam/bus'
import PanelBox from '@/components/iam/PanelBox'

import Stickers from '@/components/properties/Stickers'
import EntityContact from '@/components/properties/EntityContact.vue'
import EntityMapModal from '@/components/properties/EntityMapModal'
import WizardPubliceren from '@/components/properties/WizardPubliceren'
import CandidateHelpModal from '@/components/properties/CandidateHelpModal'
import WizardDossierDetails from '@/components/properties/WizardDossierDetails'
import WizardDossieropbouw from '@/components/properties/WizardDossieropbouw'
import WizardDeed from '@/components/properties/WizardDeed'
import WizardSalesAgreement from '@/components/properties/WizardSalesAgreement'
import WizardRentalAgreement from '@/components/properties/WizardRentalAgreement'
import HmodhPropertiesModal from '@/components/contacts/hmodh/HmodhPropertiesModal'
import EntityAuditTrail from '@/components/properties/EntityAuditTrail'
import EntityContacts from '@/components/properties/EntityContacts'
import PropertyStatus from '@/components/properties/PropertyStatus'
import EntityInvoiceContactsDisplay from '@/components/properties/EntityInvoiceContactsDisplay'
import SuspensiveConditionModal from '@/components/properties/SuspensiveConditionModal.vue'
import StartFinalInvoiceModal from '@/components/transactions/StartFinalInvoiceModal.vue'
import EntityCollaboratorsPanelBox from '@/components/properties/EntityCollaboratorsPanelBox'

import { currency } from '@/utils/helpers'
import { mapActions, mapGetters } from 'vuex'
import { successModal, errorModal } from '@/modalMessages'
import { ADDRESS_PUBLISH_OPTIONS } from '@/forms/selectOptions'
import {
  getPropertyBuyers,
  getPropertyRenters,
  getPropertyStickers,
  getPropertyStatistics,
  getPropertyFinancial,
  getPropertyConcept,
  getPropertyOwners,
  getPropertyOffices,
  makePropertyOfficePrimary,
  getPropertyGeolocation,
  getPropertyInvoiceContacts,
  getPropertyMainPicture,
  getPropertyTechnical
  , getPropertyEnergy
} from '@/services/properties'
import { checkTransactionReadyForFinalInvoice } from '@/utils/transactions'

export default {
  name: 'PropertyGeneral',
  components: {
    PanelBox,
    Stickers,
    EntityContact,
    EntityMapModal,
    CandidateHelpModal,
    HmodhPropertiesModal,
    WizardSalesAgreement,
    WizardRentalAgreement,
    WizardDossierDetails,
    WizardDossieropbouw,
    WizardPubliceren,
    WizardDeed,
    EntityAuditTrail,
    EntityContacts,
    PropertyStatus,
    EntityInvoiceContactsDisplay,
    SuspensiveConditionModal,
    StartFinalInvoiceModal,
    EntityCollaboratorsPanelBox
  },
  constants: {
    ADDRESS_PUBLISH_OPTIONS,
    RENTAL_STATUSES: [3, 11]
  },
  data () {
    return {
      buyers: [],
      renters: [],
      owners: [],
      invoiceContactsOwners: [],
      invoiceContactsBuyers: [],
      invoiceContactsRenters: [],
      offices: [],
      financialPrice: null,
      concept: null,
      stickers: [],
      statistics: {},
      mapVisible: false,
      geolocation: {},
      mainPicture: null,
      trailKey: uniqueId('trail_'),
      showSuspensiveConditionButton: false,
      technical: {},
      energy: {},
      loadingWizardDossierDetails: false
    }
  },
  computed: {
    ...mapGetters('properties', [
      'getProjectById',
      'getPropertyById'
    ]),

    propertyId () {
      return this.$route.params.id
    },
    property () {
      return this.getPropertyById(this.propertyId)
    },
    primaryOffice () {
      return this.offices.find(office => office.primary)
    },
    propertyIsProspect () {
      return this.property?.status === 1
    },
    propertyIsRental () {
      return this.property?.transaction_type === 3
    },
    propertyIsSaleOrAcquisition () {
      return [2, 4].includes(this.property?.transaction_type)
    },
    syndic () {
      return this.property.syndic
    },
    notaryOfOwners () {
      return this.property.notary_of_owners
    },
    notaryOfBuyers () {
      return this.property.notary_of_buyers
    },
    googleMapsAddress () {
      let url = ''
      if (this.property && this.property.city) {
        url = `https://maps.google.com/maps?&q=${this.property.street}+${this.property.number}+${this.property.box}+${this.property.city.name}`
      }
      return url
    },
    project () {
      return this.getProjectById(this.property.project)
    },
    showBuyers () {
      return !this.propertyIsProspect && this.propertyIsSaleOrAcquisition
    },
    showRenters () {
      return !this.propertyIsProspect && this.propertyIsRental
    },
    statusLowercase () {
      const status = this.property?.status_display_nl
      if (status) return status.toLowerCase()
      return ''
    },
    mandateEndsSoon () {
      // Mandate should end within 21 days from today
      // Only check properties with an active status (2 for sale, 3 for rent, 4 acquisition)
      if (!this.property.mandate_end_date) return false
      if (![2, 3, 4].includes(this.property.status)) return false
      const d = new Date(this.property.mandate_end_date)
      d.setDate(d.getDate() - 21)
      return new Date(this.property.mandate_end_date) > new Date() && new Date() > d
    }
  },
  created () {
    this.init()
    EventBus.$on('bid-accepted', () => {
      this.loadProperty(this.propertyId)
      this.fetchPropertyBuyers(this.propertyId)
    })
    EventBus.$on('suspensive-condition-cancelled', () => {
      this.loadProperty(this.propertyId)
      this.fetchPropertyBuyers(this.propertyId)
      this.showSuspensiveConditionButton = false
    })
  },
  beforeDestroy () {
    EventBus.$off('bid-accepted')
  },
  methods: {
    currency,
    ...mapActions('properties', ['loadProperty']),

    hideMapModal () {
      this.mapVisible = false
    },
    showCandidateHelpModal () {
      this.$refs.candidateHelpModal.show()
    },
    showHmodhPropertiesModal () {
      this.$refs.hmodhPropertiesModal.show()
    },
    async showWizardDossierDetails () {
      this.loadingWizardDossierDetails = true
      const response = await this.$refs.wizardDossierDetails.show()
      this.loadingWizardDossierDetails = false
      return response
    },
    showWizardDossieropbouw () {
      this.$refs.wizardDossieropbouw.show()
    },
    showSuspensiveConditionModal () {
      this.$refs.suspensiveConditionModal.show()
    },
    showWizardPubliceren () {
      this.$refs.wizardPubliceren.show()
    },
    showWizardBidRegistration () {
      this.$emit('show-bid-registration')
    },
    showWizardSalesAgreement () {
      this.$refs.wizardSalesAgreement.show()
    },
    showWizardRentalAgreement () {
      this.$refs.wizardRentalAgreement.show()
    },
    showWizardDeed () {
      this.$refs.wizardDeed.show()
    },
    async showMapModal () {
      const response = await getPropertyGeolocation(this.propertyId)
      this.geolocation = response.data
      this.mapVisible = true
      this.$refs.mapModal.show()
    },
    async init () {
      try {
        await Promise.all([
          this.fetchPropertyFinancial(this.propertyId),
          this.fetchPropertyStickers(this.propertyId),
          this.fetchPropertyConcept(this.propertyId),
          this.fetchPropertyRenters(this.propertyId),
          this.fetchInvoiceContacts(this.propertyId, 1),
          this.fetchInvoiceContacts(this.propertyId, 2),
          this.fetchInvoiceContacts(this.propertyId, 3),
          this.fetchPropertyOwners(this.propertyId),
          this.fetchPropertyBuyers(this.propertyId),
          this.loadStatistics(this.propertyId),
          this.fetchOffices(this.propertyId),
          this.fetchPropertyMainPicture(this.propertyId),
          this.fetchGeoLocation(this.propertyId)
        ])
        this.checkForSuspensiveCondition()
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPropertyFinancial (propertyId) {
      const response = await getPropertyFinancial(propertyId)
      this.financialPrice = response.data?.price
      return response
    },
    async fetchPropertyStickers (propertyId) {
      const response = await getPropertyStickers(propertyId)
      this.stickers = response.data?.results
      return response
    },
    async fetchPropertyBuyers (propertyId) {
      try {
        const response = await getPropertyBuyers(propertyId)
        this.buyers = response.data
        return response
      } catch {
        errorModal('Kopers kunnen niet worden geladen')
      }
    },
    async fetchPropertyRenters (propertyId) {
      try {
        const response = await getPropertyRenters(propertyId)
        this.renters = response.data
        return response
      } catch {
        errorModal('Huurders kunnen niet worden geladen')
      }
    },
    async fetchPropertyConcept (propertyId) {
      const response = await getPropertyConcept(propertyId)
      this.concept = response.data
      return response
    },
    async fetchPropertyOwners (propertyId) {
      const response = await getPropertyOwners(propertyId)
      this.owners = response.data
      return response
    },
    async fetchOffices (propertyId) {
      const response = await getPropertyOffices(propertyId)
      this.offices = response.data?.results
      return response
    },
    async fetchInvoiceContacts (propertyId, role) {
      const response = await getPropertyInvoiceContacts(propertyId, { role: role })
      if (role === 1) {
        this.invoiceContactsOwners = response.data?.results
      } else if (role === 2) {
        this.invoiceContactsBuyers = response.data?.results
      } else if (role === 3) {
        this.invoiceContactsRenters = response.data?.results
      }

      return response
    },
    async fetchPropertyMainPicture (propertyId) {
      const response = await getPropertyMainPicture(propertyId)
      this.mainPicture = response.data
      return response
    },
    async fetchGeoLocation (propertyId) {
      const response = await getPropertyGeolocation(propertyId)
      this.geolocation = response.data
      return response
    },
    async loadStatistics (propertyId) {
      try {
        const response = await getPropertyStatistics(propertyId)
        this.statistics = response.data
        return response
      } catch {
        errorModal('Er ging iets mis bij het inladen van de statistieken.')
      }
    },
    async makeOfficePrimary (office_id) {
      try {
        const response = await makePropertyOfficePrimary(this.propertyId, { office_id })
        await this.fetchOffices(this.propertyId)
        successModal('Het kantoor is primair gemaakt.')
        return response
      } catch (error) {
        let errorMessage = 'Kan kantoor niet niet primair maken.'
        if (error.response?.data?.code === 'PORTAL_PUBLICATIONS_EXIST') errorMessage = 'Kan kantoor niet primair maken omdat er actieve portaalpublicaties zijn.'
        errorModal(errorMessage)
      }
    },
    checkForSuspensiveCondition () {
      const transaction = this.property.transaction
      if (!transaction || transaction.ovab_passed) {
        this.showSuspensiveConditionButton = false
        return
      }
      if (transaction.ovab) {
        this.showSuspensiveConditionButton = true
        this.$refs.suspensiveConditionModal.transaction = transaction
      } else {
        this.showSuspensiveConditionButton = false
      }
    },
    async handleSuspensiveConditionFinished () {
      const [loadPropertyResponse, transactionReadyForFinalInvoiceResponse] = await Promise.all([this.loadProperty(this.propertyId), checkTransactionReadyForFinalInvoice(this.propertyId)])
      if (transactionReadyForFinalInvoiceResponse.showModal) {
        this.$refs.startFinalInvoiceModal.show(transactionReadyForFinalInvoiceResponse.modalData)
      }
      this.checkForSuspensiveCondition()
      return [loadPropertyResponse, transactionReadyForFinalInvoiceResponse]
    },
    async handleSuspensiveConditionEdited () {
      const response = await this.loadProperty(this.propertyId)
      this.checkForSuspensiveCondition()
      return response
    },
    async handleDossierCancelled () {
      const response = await Promise.all(
        [
          this.loadProperty(this.propertyId),
          this.fetchPropertyBuyers(this.propertyId),
          this.fetchPropertyStickers(this.propertyId)
        ]
      )
      this.checkForSuspensiveCondition()
      return response
    },
    async handleSalesAgreementSubmitted () {
      const response = await Promise.all([this.init(), this.loadProperty(this.propertyId)])
      return response
    },
    async loadPropertyTechnical (propertyId) {
      const response = await getPropertyTechnical(propertyId)
      this.technical = response.data
    },
    async loadPropertyEnergy (propertyId) {
      const response = await getPropertyEnergy(propertyId)
      this.energy = response.data
    },
    getHmodhSearchParams () {
      const params = {
        types: [this.property.type.id],
        status: this.property.status,
        cities: [this.property.city],
        price: this.financialPrice.price,
        sales_concept: 0, // to search regardless of concept
        manual: 1
      }
      if (this.technical) {
        if (this.technical.surface_trading) {
          params.surface_trading = this.technical.surface_trading
        }
        if (this.technical.surface_plot) {
          params.surface_plot = this.technical.surface_plot
        }
      }
      if (this.energy) {
        if (this.energy.epc_label) {
          params.epc_labels = [this.energy.epc_label]
        }
      }
      return params
    },
    async doHmodhSearch () {
      await this.loadPropertyTechnical(this.propertyId)
      await this.loadPropertyEnergy(this.propertyId)
      await this.$router.push(
        {
          name: 'HmodhSearch',
          params: this.getHmodhSearchParams()
        }
      )
    }
  }
}
</script>
