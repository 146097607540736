<template>
  <BaseModal ref='modal' title="Contacten/Rollen">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="entityContactsCreateUpdateModalForm"
      @submit="submit"
    >
      <FormulateInput
        type="autocomplete"
        autoCompleteType="contact"
        name="contact"
        label="Contact"
        placeholder="Kies een contact"
        validation="required"
        :disabled="editMode"
      />
      <FormulateInput
        type="select"
        name="contact_role"
        label="Rol"
        :options="roles"
        placeholder="Kies een rol"
        validation="required"
      />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        title="Opslaan"
        outer-class="tw-my-4 tw-float-right"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { successModal } from '@/modalMessages'
import { getContactRoles } from '@/services/contacts'
import { createPropertyContact, updatePropertyContact } from '@/services/properties'
import { createProjectContact, updateProjectContact } from '@/services/projects'

export default {
  name: 'EntityContactsCreateUpdateModal',
  props: {
    entityId: {
      type: [String, Number]
    },
    entityType: {
      type: String
    }
  },
  data () {
    return {
      entityContact: {},
      roles: [],
      editMode: false,
      values: {
        contact: null,
        role: null
      }
    }
  },
  methods: {
    async show (entityContact) {
      this.editMode = false
      this.entityContact = entityContact
      if (entityContact) {
        this.editMode = true
        this.values = {
          contact: entityContact.contact,
          contact_role: entityContact.contact_role.id
        }
      }
      const response = await this.fetchContactRoles()
      this.$refs.modal.show()
      return response
    },
    hide () {
      this.entityContact = {}
      this.values = {
        contact: null,
        role: null
      }
      this.$refs.modal.hide()
    },
    async fetchContactRoles () {
      const params = { type: [1, 3] } // type 1 = ALL, type 3  = PROPERTY/PROJECT contact roles
      const response = await getContactRoles({ params })
      this.roles = response.data.results.map(role => ({
        value: role.id,
        label: role.name_nl
      }))
      return response
    },
    async createEntityContact (data) {
      try {
        const payload = {
          contact: data.contact.id,
          contact_role: data.contact_role
        }
        const response = this.entityType === 'property'
          ? await createPropertyContact(this.entityId, payload)
          : await createProjectContact(this.entityId, payload)
        this.hide()
        successModal('Het contact werd toegevoegd.')
        this.$emit('created')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'entityContactsCreateUpdateModalForm')
      }
    },
    async updateEntityContact (data) {
      try {
        const payload = {
          contact: data.contact.id,
          contact_role: data.contact_role
        }
        const response = this.entityType === 'property'
          ? await updatePropertyContact(this.entityId, this.entityContact.id, payload)
          : await updateProjectContact(this.entityId, this.entityContact.id, payload)
        this.hide()
        successModal('Het contact werd aangepast.')
        this.$emit('updated')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'entityContactsCreateUpdateModalForm')
      }
    },
    async submit (data) {
      if (!this.editMode) {
        return this.createEntityContact(data)
      } else {
        return this.updateEntityContact(data)
      }
    }
  }
}
</script>
