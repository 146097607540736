<template>
  <!-- Old map modal does not render the map if the Basemodal is shown. Nesting the map modal inside the BaseModal doesn't work either.
  After lot of debugging, came up with this solution: hide the wizard to display the map, then display wizard again.-->
  <BaseModal v-show="!mapVisible" ref="modal" title="Dossieropbouw" max-width="tw-max-w-4xl">
    <WizardDossierOpbouwStep1
      ref="wizardDossierOpbouwStep1"
      v-if="activeStep === 1"
      :initial-values="initialValues"
      :property-id="propertyId"
      @showMapModal="showMapModal"
      @finished="moveForward"
    />
    <WizardDossierOpbouwStep2
      v-if="activeStep === 2"
      v-bind="{
        property: propertyValues,
        shouldCreateTransaction,
        leadToSetAsDeal
      }"
      @finished="moveForward"
    />
  </BaseModal>
</template>

<script>
import EventBus from '@/components/iam/bus'
import { scrollToModalTop } from '@/utils/helpers'
import { CONCEPT_OPTIONS } from '@/forms/selectOptions'
import { PROPERTY_TYPES } from '@/forms/schemas/portfolio/property/address'
import { getProperty, getPropertyDossierData, postPropertyDossierPreparation } from '@/services/properties'

import WizardDossierOpbouwStep1 from '@/components/properties/WizardDossierOpbouwStep1'
import WizardDossierOpbouwStep2 from '@/components/properties/WizardDossierOpbouwStep2'

export default {
  name: 'WizardDossieropbouw',
  components: { WizardDossierOpbouwStep1, WizardDossierOpbouwStep2 },
  props: {
    property: {
      type: Object,
      required: true
    },
    mapVisible: {
      type: Boolean,
      required: true
    }
  },
  constants: {
    PROPERTY_TYPES,
    CONCEPT_OPTIONS,
    TRANSACTION_TYPES: {
      2: 'Te koop',
      3: 'Te huur',
      4: 'Over te nemen'
    }
  },
  data () {
    return {
      activeStep: 1,
      stepValues: {},
      propertyValues: null,
      initialValues: null
    }
  },
  computed: {
    propertyId () {
      return this.property.id
    },
    shouldCreateTransaction () {
      return this.stepValues?.step1?.should_create_transaction
    },
    leadToSetAsDeal () {
      return this.stepValues?.step1?.lead_to_set_as_deal
    }
  },
  methods: {
    async show () {
      this.propertyValues = { ...this.property }
      const response = await getPropertyDossierData(this.property.id)
      this.initialValues = response?.data
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async showMapModal () {
      this.$emit('showMapModal')
    },
    async moveForward (data, step) {
      let response = null
      this.$set(this.stepValues, `step${step}`, data)

      if (step === 1) {
        // Refresh values for use in step 2
        const propertyResponse = await getProperty(this.propertyId)
        this.propertyValues = propertyResponse.data
        this.activeStep = 2
        this.$nextTick(() => {
          scrollToModalTop(this.$refs.modal?.$el)
        })
      }
      if (step === 2) {
        const payload = {
          is_sneak_peek: this.stepValues.step1?.is_sneak_peek || false,
          sneak_peek_picture_id: this.stepValues.step1?.sneak_peek_picture_id
        }
        response = await postPropertyDossierPreparation(this.propertyId, payload)
        this.hide()
        EventBus.$emit('initProperty')
        this.activeStep = 1
      }
      return response
    }
  }
}
</script>

<style scoped>
h2 {
  @apply tw-mt-0;
}
</style>
